<template>
  <tr :class="{'form-items__row-inactive': props.item.status == 'inactive'}">
    <wi-list-data-table-actions
      :config="config"
      :props="props"
    ></wi-list-data-table-actions>
    <td class="justify-center">
      <wi-copy-to :text="'FORM-' + props.item.id" title="Código do campo" color="primary"></wi-copy-to>
      {{ props.item.id | stringlength(5) }}
    </td>
    <td class="justify-center">
      <strong class="form-items__order">
        {{props.item.order}}
      </strong>
    </td>
    <td class="justify-left">
      <div class="form-items__type">
        <p>
          <v-icon :color="props.item.color">
            {{props.item.type.icon}}
          </v-icon>
        </p>
        <span>{{props.item.type.title}}</span>
      </div>
    </td>
    <td class="justify-left">
      <strong>{{props.item.label}}</strong>
    </td>
    <td class="justify-left">
      <span v-if="props.item.required" class="form-items__required form-items__required--active">
        Obrigatório
      </span>
      <span v-else class="form-items__required form-items__required--inactive">
        Opcional
      </span>
    </td>
    <td class="justify-left">
      <span v-if="props.item.active" class="form-items__status form-items__status--active">
        Ativo
      </span>
      <span v-else class="form-items__status form-items__status--inactive">
        Inativo
      </span>
    </td>
    <td class="justify-left">
      <v-btn outline small :disabled="props.item.type.name != 'select'" @click="openFormFieldOptions()">
        <v-icon small>format_list_bulleted</v-icon>
        <span> Opções</span>  
      </v-btn>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'FormFields',
    methods: {
      openFormFieldOptions: function () {
        this.$WiListDialog({
          wiConfig: 'form-field-option',
          filter: {
            where: 'form_field_id,' + this.props.item.id
          },
          wiEditData: {
            form_field_id: this.props.item.id
          }
        })
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style scoped>
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
  .form-items__row-inactive {
    background: rgb(209, 209, 209);
  }
  .form-items__status {
    border-width: 1px;
    border-style: solid;
    padding: 2px 5px;
  }
  .form-items__status--active {
    color: #eceff1;
    background: rgb(4, 112, 0);
  }
  .form-items__status--inactive {
    color: grey;
    background: #eceff1;
  }
  .form-items__required {
    border-width: 1px;
    border-style: solid;
    padding: 2px 5px;
  }
  .form-items__required--active {
    color: #eceff1;
    background: rgb(190, 178, 0);
  }
  .form-items__required--inactive {
    color: grey;
    background: #eceff1;
  }
  .form-items__order {
    font-size: 30px;
  }
  .form-items__type {
    border-color: rgb(198, 198, 198);
    border-width: 1px;
    border-style: solid;
    padding: 2px 5px;
    max-width: 120px;
    text-align: center;
    background: white;
  }
</style>